<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <div class="pressReleasePage position-relative">
         <section class="blockElement">
            <div class="container">
               <div class="primarybg round-md pressReleaseStory position-relative mt-0 mt-md-5 topPrimaryBox">
                  <div class="row justify-content-center">
                     <div class="col-11 col-md-9 col-lg-6 text-center py-5">
                        <h1 class="mb-0 text-white">{{contentlist.press_release_content1}}</h1>
                        <p class="d-block mt-3 mb-0 text-white">
                           {{contentlist.press_release_content2}}</p>
                        <div class="d-block text-center mt-5">
                           <router-link :to="entityPath+'/'" class="whiteBtn medium">{{contentlist.press_release_content3}}</router-link>
                        </div>
                        <div class="d-flex align-items-center justify-content-center mt-5 mediaContact">
                           <div class="d-flex align-items-center">
                              <p class="mb-0 whitecolor">{{contentlist.press_release_content4}}</p>
                              <vue-feather class="ms-2 semibold whitecolor" type="arrow-right" size="20"></vue-feather>
                           </div>
                           <div class="d-flex align-items-center ms-3">
                              <vue-feather class="semibold whitecolor" type="mail" size="20"></vue-feather>
                              <a href="mailto:press@capitalwallet.com">
                                <p class="mb-0 whitecolor ms-2">{{contentlist.press_release_content5}}</p>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- <div class="col-12 col-md-5 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                     <div class="d-flex align-items-center mb-4 mb-md-5">
                        <a href="javascript:void(0)" class=" rotate flex-shrink-0 me-3 mt-3">
                           <vue-feather class="semibold" type="sliders" size="24"></vue-feather>
                        </a>
                        <div class="position-relative searchBox d-flex justify-content-end">
                           <input type="text" placeholder="Search all Cryptos" class="form-control round-sm" name="">
                           <a href="javascript:void(0)" class="searchBoxIcon position-absolute">
                              <vue-feather class="ms-2 semibold" type="search" size="22"></vue-feather>
                           </a>
                        </div>
                     </div>
                     <div class="articleList mt-5">
                        <h3 class="mb-4">Top Articles</h3>
                        <img src="/assets/images/press-release-top-article.webp" alt="Bringing advanced Image" title="Bringing advanced" width="375" height="224" class="img-fluid round-lg">
                        <ul class="listdDot my-4">
                           <li><a href="javascript:void(0)">The Confluence of Cryptocurrency and Metaverse</a></li>
                           <li><a href="javascript:void(0)">How Can your Business Profit from Blockchain Technology</a></li>
                           <li><a href="javascript:void(0)">Introduction to Blockchain; A Public Ledger</a></li>
                           <li><a href="javascript:void(0)">What Are NFTs</a></li>
                           <li><a href="javascript:void(0)">Cryptocurrency Trading for Beginners: Complete Guide</a></li>
                        </ul>
                        <a class="readMore medium" href="javascript:void(0)">View More</a>
                     </div>
                     <div class="mt-5 mb-4 mb-md-0">
                        <h3>Follow Us On Social Media</h3>
                        <ul class="footer-social-icon d-flex align-items-center mb-0 mt-3 pressReleaseSocial">
                           <li><a target="_blank" href="https://www.facebook.com/capitalwalletau/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                           <li><a target="_blank" href="https://twitter.com/Capitalwallet_"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                           <li><a target="_blank" href="https://www.instagram.com/capitalwallet_/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                           <li><a target="_blank" href="https://www.reddit.com/r/CapitalWallet/"><i class="fa fa-reddit-alien" aria-hidden="true"></i></a></li>
                           <li><a target="_blank" href="https://www.linkedin.com/company/capitalwalletglobal/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        </ul>
                     </div>
                  </div> -->
                  <!-- col-md-7 col-lg-8 customPad-left -->
               <div class="row justify-content-center recentPress mt-5">
                  <div class="col-12 col-lg-10 mb-3 mb-md-5 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                     <h2 class="border-hover text-end position-relative mb-3 mb-md-5 mt-4 text-uppercase">
                        <span v-if="tab == 1">{{contentlist.press_release_content}}</span>
                        <span v-if="tab == 2">{{contentlist.press_release_content7}}</span>
                        <span v-if="tab == 3">{{contentlist.press_release_content8}}</span>
                     </h2>
                     <ul class="listyear d-flex align-items-center justify-content-end pt-4 pb-0 pb-md-4">
                        <li><a href="javascript:void(0)" :class="[{'active':tab == 1}]" @click.prevent="tab = 1">{{contentlist.press_release_content12}}</a></li>
                        <li><a href="javascript:void(0)" :class="[{'active':tab == 2}]" @click.prevent="tab = 2">{{contentlist.press_release_content13}}</a></li>
                        <li><a href="javascript:void(0)" :class="[{'active':tab == 3}]" @click.prevent="tab = 3">{{contentlist.press_release_content14}}</a></li>
                        
                     </ul>
                  </div>
               </div>
               <div class="block">
                  
                  <div class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400" v-if="tab == 1">
                     <h2 class="mb-md-5">{{contentlist.press_release_content18}}</h2>
                     
                     
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.benzinga.com/markets/cryptocurrency/23/09/34947470/capital-wallet-earns-prestigious-best-crypto-solution-for-payments-accolade-at-uf-awards-g" target="_blank">
                           <h6 class="semibold">Capital Wallet Earns Prestigious "Best Crypto Solution for Payments" Accolade At UF AWARDS Global 2023</h6>
                        </a>
                        <p>27 September 2023 | Benzinga.com</p>
                        <a class="semibold readMore" href="https://www.benzinga.com/markets/cryptocurrency/23/09/34947470/capital-wallet-earns-prestigious-best-crypto-solution-for-payments-accolade-at-uf-awards-g" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="primarybg medium rounded-pill">{{contentlist.product_review_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content22}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content4}} | {{contentlist.coinpedia_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content21}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content3}} | {{contentlist.finance_magnates_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content20}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content2}} | {{contentlist.finance_magnates_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="skybluebg medium rounded-pill">{{contentlist.talks_content}}</span>
                        <a href="https://www.fintechnews.org/capital-wallet-announces-two-significant-leadership-changes/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content19}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content1}} | {{contentlist.fintech_news_content}}</p>
                        <a class="semibold readMore" href="https://www.fintechnews.org/capital-wallet-announces-two-significant-leadership-changes/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                  </div>

                  <div class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400" v-if="tab == 2">
                     <h2 class="mb-md-5">{{contentlist.press_release_content18}}</h2>
                     
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">Capital Wallet Earns Prestigious "Best Crypto Solution for Payments" Accolade At UF AWARDS Global 2023</h6>
                        </a>
                        <p>27 September 2023 | Benzinga.com</p>
                        <a class="semibold readMore" href="https://www.benzinga.com/markets/cryptocurrency/23/09/34947470/capital-wallet-earns-prestigious-best-crypto-solution-for-payments-accolade-at-uf-awards-g" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     
                     <!-- <div class="topick border-bottom pb-3 mb-4">
                        <span class="primarybg medium rounded-pill">{{contentlist.product_review_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content22}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content4}} | {{contentlist.coinpedia_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content21}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content3}} | {{contentlist.finance_magnates_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content20}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content2}} | {{contentlist.finance_magnates_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="skybluebg medium rounded-pill">{{contentlist.talks_content}}</span>
                        <a href="https://www.fintechnews.org/capital-wallet-announces-two-significant-leadership-changes/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content19}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content1}} | {{contentlist.fintech_news_content}}</p>
                        <a class="semibold readMore" href="https://www.fintechnews.org/capital-wallet-announces-two-significant-leadership-changes/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div> -->
                  </div>

                  <div class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400" v-if="tab == 3">
                     <h2 class="mb-md-5">{{contentlist.press_release_content18}}</h2>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="primarybg medium rounded-pill">{{contentlist.product_review_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content22}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content4}} | {{contentlist.coinpedia_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content21}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content3}} | {{contentlist.finance_magnates_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-solution-for-payments-award-at-fmls22/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="darkgreenbg medium rounded-pill">{{contentlist.awards_content}}</span>
                        <a href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content20}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content2}} | {{contentlist.finance_magnates_content}}</p>
                        <a class="semibold readMore" href="https://www.financemagnates.com/thought-leadership/capital-wallet-wins-best-crypto-payment-solutions-provider-award-at-forex-expo-dubai/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     <div class="topick border-bottom pb-3 mb-4">
                        <span class="skybluebg medium rounded-pill">{{contentlist.talks_content}}</span>
                        <a href="https://www.fintechnews.org/capital-wallet-announces-two-significant-leadership-changes/" target="_blank">
                           <h6 class="semibold">{{contentlist.press_release_content19}}</h6>
                        </a>
                        <p>{{contentlist.press_release_date_content1}} | {{contentlist.fintech_news_content}}</p>
                        <a class="semibold readMore" href="https://www.fintechnews.org/capital-wallet-announces-two-significant-leadership-changes/" target="_blank">{{contentlist.read_more_content}}</a>
                     </div>
                     
                     
                     
                  </div>

                     <!-- <ul class="pagination mt-md-5 align-items-center">
                        <li class="page-item disabled">
                           <a title="pagination" class="page-link" href="javascript:void(0)">
                              <vue-feather type="chevron-left"></vue-feather>
                           </a>
                        </li>
                        <li class="page-item">
                           <a title="pagination" class="page-link" href="javascript:void(0)">{{contentlist.one_content}}</a>
                        </li>
                        <li class="page-item active" aria-current="page">
                           <span class="page-link">{{contentlist.two_content}}</span>
                        </li>
                        <li class="page-item">
                           <strong>...</strong>
                        </li>
                        <li class="page-item">
                           <a title="pagination" class="page-link" href="javascript:void(0)">{{contentlist.five_content}}</a>
                        </li>
                        <li class="page-item">
                           <a title="pagination" class="page-link themeBG" href="javascript:void(0)">
                              <vue-feather type="chevron-right"></vue-feather>
                           </a>
                        </li>
                     </ul> -->
                  
               </div>
            </div>
         </section>
         <section class="blockElement clientLogo aos-init pt-0" data-aos="fade-up" data-aos-duration="1000">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12 col-lg-11">
                     <h2 class="text-center mb-md-5">{{contentlist.press_release_content23}}</h2>
                     <ul class="clientLogo d-flex flex-wrap align-items-center justify-content-center">
                        <li class="pe-5"><img src="/assets/images/finance-magnates.webp" alt="Client Logo 1" title="Client Logo 1" width="180" height="75" class="img-fluid"></li>
                        <li class="pe-5"><img src="/assets/images/finance-feeds.png" alt="Client Logo 2" title="Client Logo 2" width="180" height="68" class="img-fluid"></li>
                        <li class="pe-5"><img src="/assets/images/coin-pedia.png" alt="Client Logo 3" title="Client Logo 3" width="190" height="44" class="img-fluid"></li>
                        <li class="pe-5"><img src="/assets/images/fintech-news.webp" alt="Client Logo 4" title="Client Logo 4" width="180" height="23" class="img-fluid"></li>
                        <li><img src="/assets/images/coin-world-story.png" alt="Client Logo 5" title="Client Logo 5" width="180" height="39" class="img-fluid"></li>
                     </ul>
                  </div>
               </div>
            </div>
         </section>
      </div>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-xl-10 text-center">
                  <div class="criptoIcon">
                     <span class="left-tp tow"><img src="/assets/images/left-mail.webp" alt="Icon" title="left mail" width="231" height="168" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/right-mail.webp" alt="Icon" title="right mail" width="87" height="83" class="img-fluid"><img src="/assets/images/left-mail-1.webp" alt="Icon" title="right mail" width="69" height="66" class="img-fluid onSmall"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.cta_one_content1}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_two_content2}}</p>
                     <div class="position-relative mt-5 newsletterBox">
                        <input type="text" placeholder="Email Address" class="form-control max-100 rounded-pill border-0" name="">
                        <a href="javascript:void(0)" class="newsletterBtn l-1 position-absolute rounded-pill">{{contentlist.cta_one_content3}}</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
export default ({
      mixins: [ commonAllmixins],   //anamica
      data() {
         return {
            tab: 1,
         }
      }
   });
</script>